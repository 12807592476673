@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  height: 100%;
  width: 100%;
}

.title {
    font-family: 'Monoton', cursive;
    font-size: 72px;
    color: #036a77;
}

.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.search {
    width: 700px;
    display: flex;
    justify-content: center;
}
  
.searchTerm {
    width: 100%;
    border: 3px solid #036a77;
    border-right: none;
    padding: 5px;
    height: 36px;
    border-radius: 5px 0 0 5px;
    outline: none;
    color: #9DBFAF;
}
  
.searchTerm:focus{
    color: #00B4CC;
}
  
.searchButton {
    width: 56px;
    height: 52px;
    border: 1px solid #036a77;
    background: #036a77;
    text-align: center;
    color: #fff;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    font-size: 20px;
}

.checklist {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.target {
    padding-top: 10px;
    text-align: center;
    font-size: 22px;
}

.target label {
    padding-left: 10px;
    color: #d3d3d3;
}

